import React, { useState, useEffect } from "react";
import axios from "../../plugins/axios";

import {
  Grid,
  Cell,
  Input,
  Checkbox,
  Button,
  Textarea,
} from "../../components/ui";
import {
  ContentItem,
  FormattedContentItem,
  ContentObject,
} from "../../components/content";

import { useStyletron } from "baseui";
import {
  ParagraphLarge,
  LabelLarge,
  HeadingLarge,
  ParagraphXSmall,
} from "baseui/typography";
import { FormControl } from "baseui/form-control";

import { useForm } from "react-hook-form";
import { validateEmailFormat } from "../../helpers";

import { Spring } from "react-spring/renderprops";

const Form = () => {
  const {
    register,
    errors,
    setValue,
    handleSubmit,
    formState,
    reset,
  } = useForm();
  const { dirty, isSubmitting } = formState;

  const [text, setText] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);
  const [isError, setIsError] = useState(false);

  const [css, theme] = useStyletron();

  useEffect(() => {
    register(
      { name: "text" },
      { required: ContentObject.Contact.form.requiredError }
    );
    register({ name: "agreement" });
  }, []);

  useEffect(() => setValue("text", text, dirty), [text]);
  useEffect(() => setValue("agreement", agreement, dirty), [agreement]);

  async function sendContactForm({ name, email, text }) {
    try {
      await axios.post("/contact", {
        name,
        email,
        text,
      });

      setIsFormSent(true);
      reset();
    } catch (error) {
      setIsError(true);
    }
  }

  return (
    <article data-csweb="contact-form">
      <Grid>
        <Cell span={12}>
          <div
            className={css({
              backgroundColor: theme.colors.backgroundPrimary,
              marginTop: `-${theme.sizing.scale3200}`,
              height: theme.sizing.scale3200,
              [theme.breakpoints.mediaQueries.large]: {
                marginLeft: "-32px",
                marginRight: "-32px",
              },
            })}
          ></div>
        </Cell>

        <Cell skip={[0, 0, 1]} span={[4, 5, 6]}>
          {isFormSent ? (
            <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
              {({ opacity }) => (
                <div
                  className={css({
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    opacity,
                  })}
                >
                  <HeadingLarge color={theme.colors.contentAccent}>
                    <ContentItem
                      scope="Contact"
                      accessor={`form.success.heading`}
                    />
                  </HeadingLarge>
                  <ParagraphLarge marginBottom="scale1000">
                    <ContentItem
                      scope="Contact"
                      accessor={`form.success.paragraph`}
                    />
                  </ParagraphLarge>
                  <Button
                    kind={ContentObject.Contact.form.backButton.kind}
                    label={ContentObject.Contact.form.backButton.label}
                    onClick={() => setIsFormSent(false)}
                  >
                    {ContentObject.Contact.form.backButton.title}
                  </Button>
                </div>
              )}
            </Spring>
          ) : (
            <>
              <div
                className={css({
                  marginTop: `-${theme.sizing.scale3200}`,
                  paddingTop: theme.sizing.scale1400,
                })}
              >
                <ParagraphLarge marginTop="scale0">
                  <ContentItem scope="Contact" accessor="form.lead" />
                </ParagraphLarge>
              </div>
              <form
                className={css({
                  marginTop: theme.sizing.scale1600,
                  [theme.breakpoints.mediaQueries.medium]: {
                    marginTop: theme.sizing.scale200,
                  },
                })}
                onSubmit={handleSubmit(sendContactForm)}
                noValidate
              >
                <Grid
                  flex
                  flexGridColumnCount={[1, 1, 2, 2]}
                  flexGridColumnGap={["scale100", "scale1000", "scale2400"]}
                  flexGridRowGap={["scale100", "scale1000", "scale1200"]}
                >
                  <Cell flex>
                    <FormControl error={errors.name && errors.name.message}>
                      <Input
                        name="name"
                        inputRef={register({
                          required: ContentObject.Contact.form.requiredError,
                        })}
                        placeholder={ContentObject.Contact.form.namePlaceholder}
                        error={errors.name}
                        isRequired
                      />
                    </FormControl>
                  </Cell>
                  <Cell flex>
                    <FormControl error={errors.email && errors.email.message}>
                      <Input
                        name="email"
                        inputRef={register({
                          required: ContentObject.Contact.form.requiredError,
                          validate: value =>
                            validateEmailFormat(value) ||
                            ContentObject.Contact.form.emailFormatError,
                        })}
                        placeholder={
                          ContentObject.Contact.form.emailPlaceholder
                        }
                        error={errors.email}
                        isRequired
                      />
                    </FormControl>
                  </Cell>
                </Grid>

                <FormControl error={errors.text && errors.text.message}>
                  <Textarea
                    $style={{
                      marginTop: theme.sizing.scale1600,
                      paddingTop: theme.sizing.scale600,
                    }}
                    name="text"
                    value={text}
                    onChange={e => setText(e.target.value)}
                    placeholder={ContentObject.Contact.form.textPlaceholder}
                    error={errors.text}
                    isRequired
                  />
                </FormControl>
                <div
                  className={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    marginTop: theme.sizing.scale900,
                    [theme.breakpoints.mediaQueries.medium]: {
                      flexDirection: "column",
                    },
                  })}
                >
                  <FormControl
                    error={errors.agreement && errors.agreement.message}
                  >
                    <Checkbox
                      checked={agreement}
                      onChange={e => setAgreement(e.target.checked)}
                      error={errors.agreement}
                      labelPlacement="right"
                    >
                      <ContentItem
                        scope="Contact"
                        accessor="form.agreementLabel"
                      />
                    </Checkbox>
                  </FormControl>

                  <Button
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    kind={ContentObject.Contact.form.button.kind}
                    label={ContentObject.Contact.form.button.label}
                    className={css({
                      [theme.breakpoints.mediaQueries.medium]: {
                        width: "100%",
                      },
                    })}
                  >
                    <ContentItem
                      scope="Contact"
                      accessor={`form.button.title`}
                    />
                  </Button>
                </div>
                {isError && (
                  <ParagraphXSmall $as="div" color={theme.colors.negative}>
                    <ContentItem scope="Contact" accessor={`form.apiError`} />
                  </ParagraphXSmall>
                )}
              </form>
            </>
          )}
        </Cell>
        <Cell skip={[0, 0, 1]} span={[4, 3, 3]}>
          <div
            className={css({
              marginTop: `-${theme.sizing.scale3200}`,
              paddingTop: theme.sizing.scale1400,
              paddingBottom: theme.sizing.scale2400,
              [theme.breakpoints.mediaQueries.medium]: {
                marginTop: "0px",
                paddingTop: theme.sizing.scale1200,
                paddingBottom: theme.sizing.scale1200,
              },
            })}
          >
            <LabelLarge color="contentAccent" $style={{ fontWeight: "700" }}>
              <ContentItem scope="Contact" accessor="form.details.title" />
            </LabelLarge>

            <ParagraphLarge>
              <FormattedContentItem
                scope="Contact"
                accessor="form.details.paragraph"
              />
            </ParagraphLarge>
            <ParagraphLarge>
              <strong className={css({ fontWeight: "600" })}>
                <ContentItem scope="Contact" accessor="form.details.tel" />
              </strong>
              <br />
              <a
                href=""
                className={css({
                  color: theme.colors.contentAccent,
                  textDecoration: "none",
                  "::after": {
                    content:
                      "attr(data-name) '@' attr(data-domain) '.' attr(data-tld)",
                  },
                })}
                data-name={ContentObject.Contact.form.details.email.name}
                data-domain={ContentObject.Contact.form.details.email.domain}
                data-tld={ContentObject.Contact.form.details.email.tld}
                onClick={e => {
                  e.preventDefault();
                  window.location.href =
                    "mailto:" +
                    e.currentTarget.dataset.name +
                    "@" +
                    e.currentTarget.dataset.domain +
                    "." +
                    e.currentTarget.dataset.tld;
                  return false;
                }}
              ></a>
            </ParagraphLarge>

            <LabelLarge $style={{ fontWeight: "700" }}>
              <ContentItem scope="Contact" accessor="form.details.subtitle" />
            </LabelLarge>

            <ParagraphLarge>
              <FormattedContentItem
                scope="Contact"
                accessor="form.details.subparagraph"
              />
            </ParagraphLarge>
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Form;
