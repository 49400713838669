import React from "react";

import { Grid, Cell } from "../../components/ui";
import { ContentObject } from "../../components/content";

import GoogleMapReact from "google-map-react";

import { useStyletron } from "baseui";

import MapMarker from "../../assets/images/map-marker.inline.svg";
import { useTheme } from "../../contexts/theme";

const Map = () => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  const mapStyle = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f8f8fe",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f8f8fe",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  return (
    <article data-csweb="contact-map">
      <Grid>
        <Cell span={12}>
          <div
            className={css({
              height: "600px",
              width: "100%",
              filter: useDarkTheme ? "invert(1)" : "invert(0)",
              [theme.breakpoints.mediaQueries.medium]: {
                height: "100vw",
              },
            })}
          >
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAZFB4DDZkcMFfYJXnx6Fgo6pDi-dYtLbI",
              }}
              defaultCenter={{ lat: 52.232472, lng: 20.977935 }}
              defaultZoom={13}
              options={{
                disableDefaultUI: true,
                styles: mapStyle,
              }}
            >
              <a
                href={ContentObject.Contact.form.markerLink}
                lat="52.232472"
                lng="20.977935"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MapMarker
                  height="70px"
                  className={css({
                    transform: "translate(-50%, -100%)",
                    filter: useDarkTheme ? "invert(1)" : "invert(0)",
                    cursor: "pointer",
                  })}
                />
              </a>
            </GoogleMapReact>
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Map;
